import React from 'react';

import {
  SectionAccordion,
  SectionCopy,
  SectionColumns,
  SectionResources,
  SectionQuickLinks,
  SectionQuickLinksNoIcon,
  SectionSteps,
  SectionAskAmica,
  SectionPricingInformationGrid,
  SectionMediaText,
  SectionBannerFeaturedOn,
  SectionBannerPrimary,
  SectionIsAmicaRightForYouForm,
  SectionSliderTestimonial,
  SectionButtonsList,
  SectionRecentArticles,
  SectionHero,
} from '@/components/section';

import type {
  SectionAccordionProps,
  SectionCopyProps,
  SectionColumnsProps,
  SectionResourcesProps,
  SectionQuickLinksProps,
  SectionQuickLinksNoIconProps,
  SectionStepsProps,
  SectionAskAmicaProps,
  SectionPricingInformationGridProps,
  SectionMediaTextProps,
  SectionBannerFeaturedOnProps,
  SectionBannerPrimaryProps,
  SectionIsAmicaRightForYouFormProps,
  SectionSliderTestimonialProps,
  SectionButtonsListProps,
  SectionRecentArticlesProps,
  SectionHeroProps,
} from '@/components/section';

type BaseContentModulesProps = {
  sys: {
    id: string;
  };
};

//export type SectionsWrapperProps = (SectionAccordionProps | SectionCopyProps | SectionResourcesProps ) & BaseContentModulesProps;
export type SectionsWrapperProps = (
  | SectionAccordionProps
  | SectionCopyProps
  | SectionColumnsProps
  | SectionQuickLinksNoIconProps
  | SectionResourcesProps
  | SectionStepsProps
  | SectionAskAmicaProps
  | SectionQuickLinksProps
  | SectionPricingInformationGridProps
  | SectionMediaTextProps
  | SectionBannerFeaturedOnProps
  | SectionBannerPrimaryProps
  | SectionIsAmicaRightForYouFormProps
  | SectionSliderTestimonialProps
  | SectionButtonsListProps
  | SectionRecentArticlesProps
  | SectionHeroProps
) &
  BaseContentModulesProps;

const SectionsWrapper: React.FC<SectionsWrapperProps> = (props) => {
  if (props === null) {
    return null;
  }
  const { sys } = props;
  switch (
    props.__typename // eslint-disable-line no-underscore-dangle
  ) {
    case 'SectionCopy': {
      return <SectionCopy key={sys.id} {...props} />;
    }
    case 'ContactColumns': {
      return <SectionColumns key={sys.id} {...props} />;
    }
    case 'Accordion': {
      return <SectionAccordion key={sys.id} {...props} />;
    }
    case 'Resources': {
      return <SectionResources key={sys.id} {...props} />;
    }
    case 'QuickLinksGridLearnAbout': {
      return <SectionQuickLinks key={sys.id} {...props} />;
    }
    case 'QuicklinksGrid': {
      return <SectionQuickLinksNoIcon key={sys.id} {...props} />;
    }
    case 'AskAmica': {
      return <SectionAskAmica key={sys.id} {...props} />;
    }
    case 'AmicaSectionSteps': {
      return <SectionSteps key={sys.id} {...props} />;
    }
    case 'PricingInformationGrid': {
      return <SectionPricingInformationGrid key={sys.id} {...props} />;
    }
    case 'MediaText': {
      return <SectionMediaText key={sys.id} {...props} />;
    }
    case 'BannerFeaturedOn': {
      return <SectionBannerFeaturedOn key={sys.id} {...props} />;
    }
    case 'BannerPrimary': {
      return <SectionBannerPrimary key={sys.id} {...props} />;
    }
    case 'IsAmicaRightForYouForm': {
      return <SectionIsAmicaRightForYouForm key={sys.id} {...props} />;
    }
    case 'SliderTestimonial': {
      return <SectionSliderTestimonial key={sys.id} {...props} />;
    }
    case 'ButtonsList': {
      return <SectionButtonsList key={sys.id} {...props} />;
    }
    case 'RecentArticles': {
      return <SectionRecentArticles key={sys.id} {...props} />;
    }
    case 'Hero': {
      return <SectionHero key={sys.id} {...props} />;
    }
    default: {
      return <div>not implemented</div>;
    }
  }
};

export default SectionsWrapper;
