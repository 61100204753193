import React from 'react';

import { Grid } from '@chakra-ui/react';
import type { GridProps } from '@chakra-ui/react';

export type SectionQuickLinksProps = GridProps;

const SectionGrid: React.FC<SectionQuickLinksProps> = ({
  children,
  ...rest
}) => {
  return (
    <Grid
      display="grid"
      templateColumns={{
        base: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(4, 1fr)',
      }}
      gap={6}
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default SectionGrid;
