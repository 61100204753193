import React from 'react';
import { Flex, Text, Box, Container } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { LinkButton } from '@/theme/chakra-elements';
import type { BannerProps } from '@/components/ui';

type Props = FlexProps & BannerProps;

const BannerFullWidth: React.FC<Props> = ({
  heading,
  text,
  button,
  ...rest
}) => {
  return (
    <Box
      backgroundColor="custom.accent.200"
      width="100%"
      layerStyle="bannerBottom"
      data-gtm-id="banner-full-width"
      {...rest}
    >
      <Container maxWidth="container.xl">
        <Flex
          className="bannerFullWidth"
          direction={{ base: 'column', lg: 'row' }}
          width="100%"
        >
          <Box width={{ base: '100%', lg: '50%' }}>
            <Text
              as="h3"
              textStyle="h1 conversational"
              marginBottom={6}
              maxWidth={{ base: '300px', lg: '360px', xl: '400px' }}
            >
              {heading}
            </Text>
          </Box>
          <Box width={{ base: '100%', lg: '50%' }}>
            <Box
              marginBottom={{ lg: 6 }}
              maxWidth={{ base: '500px', lg: '400px' }}
            >
              <Text textStyle="body 1" marginBottom={12}>
                {text}
              </Text>
              {button && (
                <LinkButton
                  href={button.url}
                  colorScheme="amica-orange"
                  variant="solid"
                  size="big"
                  data-gtm-id="button-banner-full-width"
                  data-gtm-tag={button.dataTagId}
                >
                  {button.text}
                </LinkButton>
              )}
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default BannerFullWidth;
