import React from 'react';

import { SectionBlock, SectionLeftRight } from '@/components/layout';
import { TextBlock, BannerSecondary } from '@/components/ui';
import { LinkButton } from '@/theme/chakra-elements';

import type { SectionLeftRightProps } from '@/components/layout';
import type { ButtonProps } from '@/components/ui';
import type { SectionButtonsListProps } from '@/components/section';
import type { BannerProps } from '@/components/ui';
import type { LayoutType } from '@/components/ui';

import { TextBlockProps } from '@/components/ui/TextBlock';
import { Box, Stack, Text } from '@chakra-ui/react';
import { SectionButtonsList } from '@/components/section';

export type SectionAskAmicaProps = {
  __typename: 'AskAmica';
  heading?: string;
  textblock: TextBlockProps;
  button?: ButtonProps;
  buttonList?: SectionButtonsListProps;
  banner?: BannerProps;
} & Omit<SectionLeftRightProps, 'children'>;

const SectionAskAmica: React.FC<SectionAskAmicaProps> = ({
  heading,
  textblock,
  button,
  buttonList,
  banner,
  layout = 'left',
}) => {
  const content = (
    <Box
      maxWidth="content.lg"
      key="textblock"
      textAlign={banner ? 'start' : 'center'}
    >
      {heading && (
        <Text textStyle="Website/Display" mb={{ base: 4 }}>
          {heading}
        </Text>
      )}
      <TextBlock
        textStyle="Website/paragraph"
        lineHeight={6}
        mb={{ base: 8, md: 14 }}
        mx={banner ? 0 : { base: 4, md: 16 }}
        {...textblock}
      />
      {button && (
        <LinkButton
          mb={{ base: 10, md: 8 }}
          href={button.url}
          colorScheme="amica-purple"
          variant="solid"
          size="cta"
          data-gtm-id="ask-amica-button"
          data-gtm-tag={button.dataTagId}
          borderRadius="full"
        >
          {button.text}
        </LinkButton>
      )}
      {buttonList && (
        <SectionButtonsList
          {...buttonList}
          color="custom.primary.700"
          spacing={6}
          justifyContent="center"
        />
      )}
    </Box>
  );

  return (
    <SectionBlock className="section-ask-amica" data-gtm-id="section-ask-amica">
      {banner ? (
        <AskAmicaAsBanner content={content} banner={banner} layout={layout} />
      ) : (
        <AskAmicaAsCta content={content} />
      )}
    </SectionBlock>
  );
};

const AskAmicaAsBanner: React.FC<{
  content: React.ReactElement;
  banner: BannerProps;
  layout: LayoutType;
}> = ({ content, banner, layout }) => {
  const displayBanner = (
    <Box className="banner" key="banner">
      {banner && (
        <BannerSecondary
          position="relative"
          zIndex={2}
          key="banner"
          {...banner}
        />
      )}
    </Box>
  );

  return (
    <SectionLeftRight
      layout={layout}
      alignItems="flex-start"
      spacing={{ base: 24, md: 10 }}
    >
      {[content, displayBanner]}
    </SectionLeftRight>
  );
};

const AskAmicaAsCta: React.FC<{ content: React.ReactElement }> = ({
  content,
}) => (
  <Stack spacing={10} align="center" justify="center">
    {content}
  </Stack>
);

export default SectionAskAmica;
