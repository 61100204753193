import React from 'react';

import { Flex } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

const SectionBlock: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Flex width="100%" direction="column" {...rest}>
      {children}
    </Flex>
  );
};

export { SectionBlock };
