import React from 'react';
import { Box, useToken } from '@chakra-ui/react';

import { SectionBlock } from '@/components/layout';
import type { ImageProps } from '@/components/ui';

export type SectionHeroProps = {
  __typename?: 'Hero';
  image: ImageProps;
  fullWidth: boolean;
};

const SectionHero: React.FC<SectionHeroProps> = ({ image }) => {
  const [sizeXl, size2xl] = useToken('sizes', ['xl', '2xl']);

  const heights = { base: 'sm', md: 'xl', xl: '2xl' };
  return (
    <SectionBlock h={heights} data-gtm-id="section-Hero">
      <Box
        bgImage={`url('${image.url}')`}
        position="absolute"
        left={0}
        right={0}
        p={6}
        bgPosition="center"
        bgRepeat="repeat-x"
        bgSize={{ base: 'cover', md: `auto ${sizeXl}`, xl: `auto ${size2xl}` }}
        h={heights}
        w="full"
      ></Box>
    </SectionBlock>
  );
};

export default SectionHero;
