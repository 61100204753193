import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { CardQuickLink } from '@/components/ui';
import { CardQuickLinkProps } from '@/components/ui/CardQuickLink';
import { SectionGrid, SectionBlock } from '@/components/layout';

export type SectionQuickLinksProps = {
  __typename: 'QuickLinksGridLearnAbout';
  heading?: string;
  linksCollection: {
    items: CardQuickLinkProps[];
  };
};

const SectionQuickLinks: React.FC<SectionQuickLinksProps> = ({
  heading,
  linksCollection,
}) => {
  const { items: links } = linksCollection;
  return (
    <SectionBlock data-gtm-id="section-quick-links">
      {heading && (
        <Text as="h3" textStyle="h3" marginBottom={12}>
          {heading}
        </Text>
      )}
      <SectionGrid>
        {links.map((link, key) => (
          <CardQuickLink {...link} key={`${link.heading}${key}`} />
        ))}
      </SectionGrid>
    </SectionBlock>
  );
};

export default SectionQuickLinks;
