import React, { useState } from 'react';
import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import theme from '@/theme/_generated/index';
import Markdown from '@/components/ui/Markdown/Markdown';

export type AccordionItemDataType = { title: string; description: string };
export type AccordionProps = {
  title: string;
  list: AccordionItemDataType[];
} & FlexProps;

const AccordionChakra: React.FC<AccordionProps> = ({
  title,
  list,
  ...rest
}) => {
  const [isHover, setIsHover] = useState({ active: false, index: 0 });
  const [isExpanded, setIsExpanded] = useState<number>(-1);

  return (
    <Flex flexDirection="column" data-gtm-id="accordion" {...rest}>
      {title && (
        <Text mb={theme.space[8]} textStyle="h3">
          {title}
        </Text>
      )}
      <Accordion
        allowToggle={true}
        allowMultiple={false}
        boxShadow="md"
        borderRadius={theme.radii.md}
        maxWidth="718px"
        backgroundColor="#FFFFFF"
        onChange={(e: number) => setIsExpanded(e)}
      >
        {list &&
          list.length &&
          list.map((item, i) => (
            <AccordionItem
              borderTopColor={i === 0 ? 'transparent' : 'inherit'}
              borderBottomColor={
                i === list.length - 1 ? 'transparent' : 'inherit'
              }
              _focus={{ boxShadow: 'none' }}
              _hover={{ backgroundColor: '#FFFFFF' }}
              key={i}
            >
              <AccordionButton
                _focus={{ boxShadow: 'none' }}
                h={theme.space[20]}
                _hover={{ textDecoration: 'underline' }}
                onMouseEnter={() => setIsHover({ active: true, index: i })}
                onMouseLeave={() => setIsHover({ active: false, index: i })}
                data-gtm-id="accordion-button"
                sx={{
                  '.chakra-accordion__icon': {
                    transform: isExpanded === i ? 'initial' : 'rotate(-90deg)',
                  },
                }}
              >
                <Box flex="1" textAlign="left">
                  <Text textStyle="body 1 bold">{item.title}</Text>
                </Box>
                <AccordionIcon
                  backgroundColor={
                    isHover.active && isHover.index === i
                      ? theme.colors.custom.primary[200]
                      : theme.colors.custom.primary[50]
                  }
                  width="25px"
                  height="25px"
                  ml={4}
                  borderRadius="full"
                />
              </AccordionButton>

              <AccordionPanel>
                <Box textStyle="body 1">
                  <Markdown body={item.description} />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </Flex>
  );
};

export default AccordionChakra;
