import { SectionGrid } from '@/components/layout';
import { CardQuickLinkStatistic } from '@/components/ui';
import { CardQuickLinkStatisticProps } from '@/components/ui/CardQuickLinkStatistic/CardQuickLinkStatistic';
import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { SectionQuickLinksNoIconProps } from './SectionQuickLinksNoIcon';

export type SectionQuickLinksStatisticProps = SectionQuickLinksNoIconProps & {
  quicklinksCollection: {
    items: CardQuickLinkStatisticProps[];
  };
};
const SectionQuickLinksStatistics: React.FC<SectionQuickLinksStatisticProps> =
  ({ heading, quicklinksCollection: { items }, subtext }) => {
    const displayHeading = heading && (
      <Text
        as="h4"
        textStyle="Website/h4"
        marginTop={{ base: 8, md: 0 }}
        marginBottom={{ base: 0, md: 16 }}
        textAlign="center"
      >
        {heading}
      </Text>
    );

    const displaySubtext = subtext && (
      <Text
        textStyle="Website/paragraph-small"
        marginTop={{ base: 2, md: 6 }}
        textAlign="center"
        color="custom.text.subdued"
      >
        {subtext}
      </Text>
    );

    const displayGrid = (
      <SectionGrid
        gap={{ base: 2, md: 6 }}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          xs: 'repeat(2, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
      >
        {items.map(({ heading, content, icon }) => (
          <CardQuickLinkStatistic
            heading={heading}
            content={content}
            icon={icon}
            key={`${heading}`}
          />
        ))}
      </SectionGrid>
    );

    return (
      <>
        <Flex direction={{ base: 'column-reverse', md: 'column' }}>
          {displayHeading}
          {displayGrid}
        </Flex>
        {displaySubtext}
      </>
    );
  };

export default SectionQuickLinksStatistics;
