import React from 'react';

import { Stack, Box } from '@chakra-ui/react';

import { SectionBlock } from '@/components/layout';
import { LinkButton, Link } from '@/theme/chakra-elements';

import { ButtonProps } from '@/components/ui';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import type { StackProps } from '@chakra-ui/react';

export type SectionButtonsListProps = {
  __typename: 'ButtonsList';
  displayAsLinks?: boolean;
  buttonsCollection: {
    items: ButtonProps[];
  };
} & StackProps;

const SectionButtonsList: React.FC<SectionButtonsListProps> = ({
  buttonsCollection,
  displayAsLinks = false,
  ...rest
}) => {
  const { items: buttons = [] } = buttonsCollection;
  return (
    <SectionBlock data-gtm-id="section-button-list">
      <Stack direction={{ base: 'column', md: 'row' }} {...rest}>
        {buttons.map((button, index) => {
          //TODO: Would have been great to  be able to choose this in th cms
          const { url, text, colorScheme, size = 'small', dataTagId } = button;

          if (displayAsLinks) {
            return (
              <Box key={`${url}${index}`}>
                <Link
                  href={url}
                  textStyle="link"
                  size={size}
                  data-gtm-id="link-button-list"
                  data-gtm-tag={dataTagId}
                >
                  {text}
                </Link>
              </Box>
            );
          }
          if (index === buttons.length - 1) {
            // Note: This might be a double up with ButtonNext but need to
            // refactor this
            return (
              <Box key={`${url}${index}`}>
                <LinkButton
                  href={url}
                  rightIcon={<ArrowForwardIcon />}
                  colorScheme={colorScheme}
                  variant="outline"
                  size={size}
                  data-gtm-id="link-button-list"
                  data-gtm-tag={dataTagId}
                >
                  {text}
                </LinkButton>
              </Box>
            );
          }
          return (
            <Box key={`${url}${index}`}>
              <LinkButton
                href={url}
                colorScheme={colorScheme}
                size={size}
                data-gtm-id="link-button-list"
                data-gtm-tag={dataTagId}
              >
                {text}
              </LinkButton>
            </Box>
          );
        })}
      </Stack>
    </SectionBlock>
  );
};

export default SectionButtonsList;
