import { SectionGrid } from '@/components/layout';
import { CardQuickLinkNoIcon } from '@/components/ui';
import { CardQuickLinkNoIconProps } from '@/components/ui/CardQuickLinkNoIcon';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { SectionQuickLinksNoIconProps } from './SectionQuickLinksNoIcon';

export type SectionQuickLinksDefaultProps = SectionQuickLinksNoIconProps & {
  quicklinksCollection: {
    items: CardQuickLinkNoIconProps[];
  };
};

const SectionQuickLinksDefault: React.FC<SectionQuickLinksDefaultProps> = ({
  heading,
  quicklinksCollection: { items },
}) => {
  return (
    <>
      {heading && (
        <Text as="h3" textStyle="h3" marginBottom={12} textAlign="center">
          {heading}
        </Text>
      )}
      <SectionGrid>
        {items.map(({ heading, content, link }) => (
          <CardQuickLinkNoIcon
            heading={heading}
            content={content}
            link={link && link}
            key={`${heading}${link}`}
          />
        ))}
      </SectionGrid>
    </>
  );
};

export default SectionQuickLinksDefault;
