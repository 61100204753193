import React from 'react';
import Head from 'next/head';

import MetaImage from '@/images/cover.jpg';

export type MetaTagProps = {
  title: string;
  description?: string;
  image?: string;
  slug?: string;
};

const defaultDescription = 'amica | separate smarter';

const AmicaMetaTags: React.FC<MetaTagProps> = ({
  title,
  description = defaultDescription,
  image = MetaImage.src,
  slug = '',
}) => {
  const fullImageUrl = image.startsWith('/')
    ? `${process.env.SITE_URL}${image}`
    : image;

  const canonical = `${process.env.SITE_URL}/${slug}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={fullImageUrl} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={fullImageUrl} />
      <link rel="canonical" href={canonical} />
    </Head>
  );
};

export default AmicaMetaTags;
