import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

import { Link } from '@/theme/chakra-elements';
import { ButtonArrowRound } from '@/components/ui';

export type CardQuickLinkNoIconProps = {
  heading: string;
  content: string;
  link: string;
} & FlexProps;

const CardQuickLinkNoIcon: React.FC<CardQuickLinkNoIconProps> = ({
  heading,
  content,
  link,
  ...rest
}) => {
  return (
    <Link
      href={link}
      _hover={{ textDecoration: 'none' }}
      data-gtm-id="card-quick-link-no-icon"
    >
      <Flex
        flexDirection="column"
        borderRadius="md"
        backgroundColor="#FFFFFF"
        minHeight="100%"
        width="100%"
        p={6}
        position="relative"
        borderWidth="1px"
        borderColor="white"
        borderTopWidth="12px"
        borderTopColor="custom.primary.600"
        _hover={{
          borderColor: 'custom.neutral.100',
          boxShadow: 'lg',
          borderTopColor: 'custom.primary.600',
        }}
        role="group"
        color="custom.text.default"
        {...rest}
      >
        <Text
          textStyle="h4"
          mb={6}
          _groupHover={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {heading}
        </Text>
        <Text textStyle="body 2" mb={12}>
          {content}
        </Text>
        <Box position="absolute" bottom={6} right={6}>
          <ButtonArrowRound deactivated={true} url={link} />
        </Box>
      </Flex>
    </Link>
  );
};

export default CardQuickLinkNoIcon;
