import React, { useEffect, useState } from 'react';
import type { BreadcrumbProps } from '@chakra-ui/react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Link,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';

type Props = {
  notFound?: boolean;
} & BreadcrumbProps;

const Breadcrumbs: React.FC<Props> = ({ notFound = false, ...props }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([
    { breadcrumb: '', href: '/' },
  ]);

  const convertBreadcrumb = (path: string) => {
    return path
      .replace(/-/g, ' ')
      .replace(/\b\w/g, (l) => l.toUpperCase())
      .replace(/Amica/g, 'amica');
  };

  const router = useRouter();

  useEffect(() => {
    // when the slug is stored as a query in a dynamic route
    if (router && router.query.slug && !router.pathname.includes('/blog')) {
      const slugsQueries =
        typeof router.query.slug === 'string'
          ? [router.query.slug]
          : router.query.slug || [];
      const pathArray = slugsQueries.map((path: string, i: number) => {
        return {
          breadcrumb:
            notFound && i === slugsQueries.length - 1
              ? 'Page not found'
              : convertBreadcrumb(path),
          href: '/' + slugsQueries.slice(0, i + 1).join('/'),
        };
      });
      setBreadcrumbs(pathArray);
    } else if (router) {
      // when the slug is stored as the pathname
      const slugsPaths = router.asPath.split('/');
      // remove the home page one as it is hardcoded in
      slugsPaths.shift();
      if (router.asPath.includes('/page/')) {
        // path will besomething like: /blog/page/2
        // remove the page number and page so these do not appear in the breadcrumbs
        slugsPaths.pop();
        slugsPaths.pop();
      }
      const pathArray = slugsPaths.map((path: string, i: number) => {
        return {
          breadcrumb:
            notFound && i === slugsPaths.length - 1
              ? 'Page not found'
              : convertBreadcrumb(path),
          href: '/' + slugsPaths.slice(0, i + 1).join('/'),
        };
      });
      setBreadcrumbs(pathArray);
    }
  }, [router, setBreadcrumbs, notFound]);

  if (!breadcrumbs || (breadcrumbs.length && !breadcrumbs[0].breadcrumb)) {
    return null;
  }

  return (
    <Breadcrumb data-gtm-id="breadcrumb" {...props}>
      <BreadcrumbItem>
        <BreadcrumbLink as={NextLink} href="/" data-gtm-id="link-breadcrumb">
          <Link
            style={{
              textTransform: 'capitalize',
              fontWeight:
                breadcrumbs.length && breadcrumbs[0].breadcrumb
                  ? 'bold'
                  : 'normal',
              textDecoration:
                breadcrumbs.length && breadcrumbs[0].breadcrumb
                  ? 'underline'
                  : 'none',
            }}
            href="/"
          >
            Home
          </Link>
        </BreadcrumbLink>
      </BreadcrumbItem>

      {breadcrumbs.length &&
        breadcrumbs[0].breadcrumb &&
        breadcrumbs.map((breadcrumb, i) => (
          <BreadcrumbItem key={i}>
            <BreadcrumbLink
              as={NextLink}
              href={breadcrumb.href}
              data-gtm-id="link-breadcrumb"
            >
              <Link
                style={{
                  fontWeight: i === breadcrumbs.length - 1 ? 'normal' : 'bold',
                  textDecoration:
                    i === breadcrumbs.length - 1 ? 'none' : 'underline',
                }}
                _hover={{
                  textDecoration: 'none',
                }}
                href={breadcrumb.href}
              >
                {breadcrumb.breadcrumb}
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
