import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import type { ImageProps } from '@/components/ui';
import React from 'react';

export type CardQuickLinkPanelProps = {
  heading: string;
  content: string;
  image?: ImageProps;
} & FlexProps;

const CardQuickLinkPanel: React.FC<CardQuickLinkPanelProps> = ({
  heading,
  content,
  image,
  ...rest
}) => {
  return (
    <Box role="group" gap="0" h="full" maxW="content.sm">
      <Flex
        flexDirection="column"
        align="center"
        borderRadius="xl"
        backgroundColor="#FFFFFF"
        height="full"
        pt={{ base: 6, md: 10 }}
        justifyContent="start"
        {...rest}
      >
        <Box h="15%" flexGrow={1}>
          <Text
            textStyle="Website/h6"
            textAlign="center"
            verticalAlign="middle"
            pt={1}
            px={{ base: 4, md: 10 }}
            w="full"
            textOverflow="ellipsis"
          >
            {heading}
          </Text>
        </Box>
        {image?.url && (
          <Image
            alt=""
            src={image.url}
            width="full"
            height="auto"
            ignoreFallback
            quality={100}
          />
        )}

        <Box h="15%" flexGrow={1} mb={16}>
          <Text
            flexGrow={0}
            flexShrink={0}
            textStyle="Website/paragraph"
            textAlign="center"
            pt={1}
            px={{ base: 4, md: 10 }}
            w="full"
            wordBreak="break-word"
            textOverflow="ellipsis"
            noOfLines={8}
          >
            {content}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default CardQuickLinkPanel;
