import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

import { LinkButton } from '@/theme/chakra-elements';
import { Markdown } from '@/components/ui';
import type { ButtonProps } from '@/components/ui';

export type TextBlockProps = {
  content: string;
  headingsFont?: string;
  button?: ButtonProps;
  subcontent?: string;
} & FlexProps;

const TextBlock: React.FC<TextBlockProps> = ({
  content,
  button,
  subcontent,
  headingsFont,
  ...rest
}) => {
  return (
    <Flex
      className="textBlock"
      flexDirection="column"
      alignSelf={{ base: 'flex-start', lg: 'auto' }}
      sx={{
        // h1 default has no margin
        'h1 ~ p': {
          marginTop: 8,
        },
        '& > div > h2:first-of-type, & > div > h3:first-of-type,& > div > h4:first-of-type':
          { marginTop: 0 },
        '& > div > p:last-child': { marginBottom: 0 },
      }}
      data-gtm-id="text-block"
      {...rest}
    >
      <Markdown body={content} headingsFont={headingsFont} />
      {button && (
        <Box mb={subcontent ? 6 : 0}>
          <LinkButton
            href={button.url}
            width="fit-content"
            colorScheme={button.colorScheme}
            marginTop={{ base: 6, xl: 10 }}
            size={button.size ?? 'small'}
            data-gtm-id="link-text-block"
            data-gtm-tag={button.dataTagId}
          >
            {button.text}
          </LinkButton>
        </Box>
      )}
      {subcontent && <Markdown body={subcontent} headingsFont={headingsFont} />}
    </Flex>
  );
};

export default TextBlock;
