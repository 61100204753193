import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { LinkButton } from '@/theme/chakra-elements';
import { ButtonProps, ImageProps } from '@/components/ui';
import { SectionBlock } from '@/components/layout';
import SectionStepsAccordion from './SectionStepsAccordion';

export type Step = {
  text: string;
  subtext?: string;
  image?: ImageProps;
  button?: ButtonProps;
};

export type SectionStepsType = 'list' | 'accordion';

export type SectionStepsProps = {
  __typename?: 'AmicaSectionSteps';
  heading: string;
  stepsCollection: { items: Step[] };
  highlight?: string;
  button?: ButtonProps;
  type?: SectionStepsType;
} & FlexProps;

const SectionSteps: React.FC<SectionStepsProps> = ({
  heading,
  stepsCollection,
  highlight,
  button,
  type = 'list',
  ...rest
}) => (
  <SectionBlock data-gtm-id="section-steps" {...rest}>
    {type === 'accordion' ? (
      <StepsAsAccordion
        heading={heading}
        stepsCollection={stepsCollection}
        button={button && button}
      />
    ) : (
      <StepsAsList
        heading={heading}
        stepsCollection={stepsCollection}
        highlight={highlight && highlight}
        button={button && button}
      />
    )}
  </SectionBlock>
);

const StepsAsList: React.FC<SectionStepsProps> = ({
  heading,
  stepsCollection,
  highlight,
  button,
}) => {
  const { items: steps } = stepsCollection;
  const halfwayThrough = Math.ceil(steps.length / 2);
  const arrayFirstHalf = steps.slice(0, halfwayThrough);
  const arraySecondHalf = steps.slice(halfwayThrough, steps.length);

  const stepsColumn = (step: Step, i: number, isSecondColumn: boolean) => (
    <Flex mb={10} key={i}>
      <Flex
        borderRadius="full"
        backgroundColor="custom.primary.50"
        color="custom.primary.700"
        minWidth="40px"
        minHeight="40px"
        w="40px"
        h="40px"
        justifyContent="center"
        alignItems="center"
      >
        <Text textStyle="quote" color="custom.primary.700">
          {isSecondColumn ? i + 1 + halfwayThrough : i + 1}
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        ml={4}
        justifyContent="center"
        maxWidth="450px"
      >
        <Text textStyle="body 1">{step.text}</Text>
        {step.button && (
          <Box>
            <LinkButton
              href={step.button.url}
              colorScheme="amica-purple"
              variant="solid"
              size={step.button.size ?? 'big'}
              data-gtm-tag={step.button.dataTagId}
              mt={3}
            >
              {step.button.text}
            </LinkButton>
          </Box>
        )}
      </Flex>
    </Flex>
  );

  return (
    <>
      {heading && (
        <Text as="h3" textStyle="h3" marginBottom={12}>
          {heading}
        </Text>
      )}
      <Flex wrap="wrap">
        <Box mr={4}>
          {arrayFirstHalf.length &&
            arrayFirstHalf.map((step, i) => stepsColumn(step, i, false))}
        </Box>
        <Box>
          {arraySecondHalf.length &&
            arraySecondHalf.map((step, i) => stepsColumn(step, i, true))}
        </Box>
      </Flex>

      {highlight && (
        <Box
          maxWidth="550px"
          p={6}
          mb={12}
          borderRadius="lg"
          backgroundColor="custom.primary.50"
        >
          {highlight}
        </Box>
      )}
      {button && (
        <Box>
          <LinkButton
            href={button.url}
            colorScheme={button.colorScheme}
            variant="outline"
            size={button.size ?? 'small'}
            data-gtm-id="link-section-steps"
            data-gtm-tag={button.dataTagId}
          >
            {button.text}
          </LinkButton>
        </Box>
      )}
    </>
  );
};

const StepsAsAccordion: React.FC<SectionStepsProps> = ({
  heading,
  stepsCollection,
  button,
}) => {
  return (
    <SectionStepsAccordion
      heading={heading}
      stepsCollection={stepsCollection}
      button={button}
    />
  );
};

export default SectionSteps;
