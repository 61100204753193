import React from 'react';

import { SectionBlock } from '@/components/layout';
import { BannerFeaturedOn } from '@/components/ui';
import type { ImageLinkProps } from '@/components/ui';

export type SectionBannerFeaturedOnProps = {
  __typename: 'BannerFeaturedOn';
  heading: string;
  imageLinksCollection: {
    items: ImageLinkProps[];
  };
};

const SectionBannerFeaturedOn: React.FC<SectionBannerFeaturedOnProps> = ({
  heading,
  imageLinksCollection,
  ...rest
}) => {
  const { items: imageLinks } = imageLinksCollection;
  return (
    <SectionBlock data-gtm-id="section-banner-featured-on" {...rest}>
      <BannerFeaturedOn imageLinks={imageLinks} heading={heading} />
    </SectionBlock>
  );
};

export default SectionBannerFeaturedOn;
