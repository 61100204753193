import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { LinkButton } from '@/theme/chakra-elements';
import type { BannerProps } from '@/components/ui';

type Props = FlexProps & BannerProps;

const BannerPrimary: React.FC<Props> = ({
  children,
  heading,
  text,
  button,
  ...rest
}) => {
  return (
    <Box
      backgroundColor="custom.neutral.25"
      padding={{ base: 6, lg: 8, xl: 12 }}
      shadow={{ xl: 'lg' }}
      borderRadius="lg"
      width="100%"
      data-gtm-id="banner-primary"
      {...rest}
    >
      <Box marginBottom={6}>
        <Text as="h3" textStyle="h3" marginBottom={4}>
          {heading}
        </Text>
        <Text textStyle="body 1" maxWidth="content.lg">
          {text}
        </Text>
      </Box>
      {button && (
        <LinkButton
          href={button.url}
          colorScheme="amica-grey"
          variant="solid"
          size="big"
          data-gtm-id="link-banner-primary"
          data-gtm-tag={button.dataTagId}
        >
          {button.text}
        </LinkButton>
      )}
      {children}
    </Box>
  );
};

export default BannerPrimary;
