import React from 'react';
import { Button } from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

type Props = ButtonProps;

const ButtonBack: React.FC<Props> = ({ children = 'Back', ...props }) => {
  return (
    <Button
      colorScheme="amica-purple"
      leftIcon={<ArrowBackIcon />}
      variant="outline"
      data-gtm-id="button-back"
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonBack;
