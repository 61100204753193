import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

import { Box, Flex } from '@chakra-ui/react';

import { clampDimensions } from '@/utils/helpers';
import { Image } from '@/theme/chakra-elements';
import { assertNever } from '@/utils/types';

import { TextBlock, LogoAndTag, BannerAmicaOne } from '@/components/ui';
import { SectionBlock, SectionLeftRight } from '@/components/layout';

import type { SectionLeftRightProps } from '@/components/layout';
import type { ImageProps } from '@/components/ui';
import type { TextBlockProps } from '@/components/ui/TextBlock';

type MediaOptions =
  | { __typename: 'UiTextMediaImage'; image: ImageProps }
  | { __typename: 'SliderPhone'; imagesCollection: { items: ImageProps[] } };

const SliderComponentWithNoSSR = dynamic(
  import('@/components/ui/SliderPhone'),
  {
    ssr: false,
  }
);

export type SectionMediaTextProps = {
  __typename: 'MediaText';
  textblock: TextBlockProps;
  media: MediaOptions;
  branding: 'None' | 'Amica' | 'Amica One' | 'Amica One CTA';
  layout: 'left' | 'right';
  heading?: string;
} & Omit<SectionLeftRightProps, 'children'>;

const Media: React.FC<{ media: MediaOptions }> = ({ media }) => {
  const phoneMaxWidth = 300;
  const [isClient, setIsClient] = useState(false);
  const PAGE_LOAD_DELAY = 200;
  useEffect(() => {
    // Not the best but need to delaty to get it loading again after client load
    // https://github.com/akiran/react-slick/issues/1245
    setTimeout(() => {
      setIsClient(true);
    }, PAGE_LOAD_DELAY);
  }, []);

  let item: React.ReactElement;
  if (!media) {
    return <div>No Image</div>;
  }
  switch (media.__typename) {
    case 'UiTextMediaImage': {
      const { image } = media;
      const { width, height } = clampDimensions({
        width: image.width,
        height: image.height,
        clampWidth: phoneMaxWidth,
      });
      item = (
        <Image
          src={image.url}
          width={width}
          height={height}
          alt={image.description}
          zIndex={2}
          quality={100}
        />
      );
      break;
    }
    case 'SliderPhone': {
      // Note: need extra max width because slick slider component has extra paddingx 50px
      item = (
        <Box
          flex="none"
          maxWidth={phoneMaxWidth + 60}
          position="relative"
          paddingBottom={12}
          zIndex={2}
          key={isClient ? 'client' : 'server'}
        >
          <SliderComponentWithNoSSR images={media.imagesCollection.items} />
        </Box>
      );
      break;
    }
    default: {
      return assertNever(media);
    }
  }
  return item;
};

const SectionMediaText: React.VFC<SectionMediaTextProps> = ({
  media,
  textblock,
  branding = 'None',
  layout = 'left',
  heading,
}) => {
  return branding === 'Amica One CTA' ? (
    <BannerAmicaOne
      image={'image' in media ? media.image : undefined}
      textblock={textblock}
      heading={heading}
    />
  ) : (
    <SectionBlock className="section-media-text">
      <SectionLeftRight layout={layout} alignItems="center">
        {[
          <Box key="media" className="media">
            <Media media={media} />
          </Box>,
          <Flex key="textblock" flexDirection="column">
            {branding !== 'None' && (
              <LogoAndTag logoHeight={8} mb="0" branding={branding} />
            )}
            <TextBlock maxWidth="content.md" {...textblock} />
          </Flex>,
        ]}
      </SectionLeftRight>
    </SectionBlock>
  );
};

export default SectionMediaText;
