import React, { useState } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import theme from '@/theme/_generated';
import {
  ButtonBack,
  ButtonNext,
  ButtonQuestion,
  Markdown,
} from '@/components/ui';
import { useRouter } from 'next/router';
import { onClickAnchorLink } from '../../../utils/helpers';

type StepProps = {
  title: string;
  content: string;
  questionTitle: string;
  eligibleQuestion: string;
  ineligibleQuestion: string;
  questionFooter: string;
};

export type SectionIsAmicaRightForYouFormProps = {
  __typename: 'IsAmicaRightForYouForm';
  sys: { id: string };
  ineligible: {
    title: string;
    content: string;
  };
  stepsCollection: {
    items: StepProps[];
  };
} & FlexProps;

enum QuestionSelection {
  INELIGIBLE = 'INELIGIBLE',
  ELIGIBLE = 'ELIGIBLE',
  NONE = 'NONE',
}

const FORM_ROUTE = '/is-amica-right-for-me';
const INELIGIBLE_ROUTE = `${FORM_ROUTE}/not-suitable`;
const ELIGIBLE_ROUTE = `${FORM_ROUTE}/eligible`;

type ProgressIndicatorProps = {
  progress: number;
};

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ progress }) => (
  <Box
    position="absolute"
    left="0px"
    bottom="0px"
    borderRadius="3px"
    border="3px"
    transition="width 0.3s ease-in-out"
    borderStyle="solid"
    borderColor="custom.primary.500"
    width={`${Math.max(progress, 5)}%`}
  />
);

const SectionIsAmicaRightForYouForm: React.FC<SectionIsAmicaRightForYouFormProps> =
  ({ stepsCollection: { items: steps }, sys, ...rest }) => {
    const router = useRouter();

    const { step } = router.query;

    const [stepNumber, setStepNumber] = useState(Number(step) || 1);
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionSelection>(
      QuestionSelection.NONE
    );

    const hasPreviousStep = stepNumber > 1;
    const hasNextStep = stepNumber < steps.length;
    const isEligible = stepNumber === steps.length;

    if (steps.length === 0) {
      return null;
    }

    // go to the next step of the process
    const nextStep = () => {
      if (selectedQuestion === QuestionSelection.INELIGIBLE) {
        router.push(INELIGIBLE_ROUTE);
        return;
      }

      if (isEligible) {
        router.push(ELIGIBLE_ROUTE);
        return;
      }

      if (hasNextStep) {
        const nextStep = stepNumber + 1;
        setStepNumber(nextStep);
        setSelectedQuestion(QuestionSelection.NONE);
        onClickAnchorLink('amica-question');
        router.push(
          {
            pathname: '/is-amica-right-for-me',
            query: { step: nextStep },
          },
          undefined,
          { shallow: true }
        );
      }
      return;
    };

    // go to the previous step of the process
    const previousStep = () => {
      if (hasPreviousStep) {
        const prevStep = stepNumber - 1;
        setStepNumber(prevStep);
        setSelectedQuestion(QuestionSelection.NONE);
        router.push(
          {
            pathname: '/is-amica-right-for-me',
            query: { step: prevStep },
          },
          undefined,
          { shallow: true }
        );
      }
    };

    const {
      title = '',
      content = '',
      questionTitle = '',
      eligibleQuestion = '',
      ineligibleQuestion = '',
      questionFooter = '',
    } = steps[stepNumber - 1] || {};

    return (
      <Flex
        backgroundColor="white"
        p={[theme.space[4], theme.space[8], theme.space[12]]}
        flexDirection="column"
        borderRadius={theme.radii.xl}
        shadow={theme.shadows.lg}
        key={sys.id}
        position="relative"
        overflow="hidden"
        data-gtm-id="section-is-amica-right-for-you-form"
        {...rest}
      >
        <Box id="amica-question">
          <Text textStyle="quote" mb={4}>
            <strong>{stepNumber}</strong> of {steps.length}
          </Text>
          <Text as="h2" textStyle="h2" mb={10}>
            {title}
          </Text>
          <Markdown body={content} />
          <Flex
            backgroundColor="custom.neutral.25"
            p={[theme.space[4], theme.space[8]]}
            flexDirection="column"
            borderRadius={theme.radii.md}
            shadow={theme.shadows.md}
            my={16}
          >
            <Text as="h4" textStyle="h4">
              {questionTitle}
            </Text>
            <Box mt={10} mb={6}>
              <ButtonQuestion
                isSelected={selectedQuestion === QuestionSelection.ELIGIBLE}
                onClick={() => setSelectedQuestion(QuestionSelection.ELIGIBLE)}
                data-gtm-tag="eligible"
                textStyle="body 1"
              >
                <Markdown body={eligibleQuestion} />
              </ButtonQuestion>
              <ButtonQuestion
                isSelected={selectedQuestion === QuestionSelection.INELIGIBLE}
                onClick={() =>
                  setSelectedQuestion(QuestionSelection.INELIGIBLE)
                }
                data-gtm-tag="not-suitable"
                textStyle="body 1"
              >
                <Markdown body={ineligibleQuestion} />
              </ButtonQuestion>
            </Box>
            <Box textStyle="body 1">
              <Markdown body={questionFooter} />
            </Box>
          </Flex>
        </Box>
        <Flex justifyContent="space-between">
          <Box>
            {hasPreviousStep && (
              <ButtonBack
                variant="outline"
                onClick={previousStep}
                data-gtm-tag="form-previous"
              >
                Back
              </ButtonBack>
            )}
          </Box>
          <ButtonNext
            onClick={nextStep}
            disabled={selectedQuestion === QuestionSelection.NONE}
            data-gtm-tag="form-next"
          >
            Next
          </ButtonNext>
        </Flex>
        <ProgressIndicator progress={((stepNumber - 1) / steps.length) * 100} />
      </Flex>
    );
  };

export default SectionIsAmicaRightForYouForm;
