import { SectionLeftRight } from '@/components/layout';
import { ButtonProps } from '@/components/ui';
import { LinkButton } from '@/theme/chakra-elements';
import { FlexProps } from '@chakra-ui/react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Image } from '@/theme/chakra-elements';
import { Step } from '@/components/section/SectionSteps/SectionSteps';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export type SectionStepsAccordionProps = {
  heading: string;
  stepsCollection: { items: Step[] };
  button?: ButtonProps;
} & FlexProps;

const SectionStepsAccordion: React.FC<SectionStepsAccordionProps> = ({
  heading,
  stepsCollection,
  button,
}) => {
  const [step, setStep] = React.useState(0);
  const { items: steps } = stepsCollection;

  const displaySubheader = (
    <Text textStyle="paragraph" color="custom.primary.700" mb={4}>
      Features
    </Text>
  );

  const displayHeader = (
    <Text textStyle="Website/h2" mb={10}>
      {heading}
    </Text>
  );

  const displaySteps = (
    <Accordion defaultIndex={step}>
      {steps.map((step, i) => (
        <AccordionItem key={i} border="none">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                onClick={() => setStep(i)}
                _hover={{ bg: 'inherit' }}
                py={1}
                px={1}
              >
                <StepIndex current={isExpanded} index={i + 1} />
                <Flex ml={3} mr={1}>
                  <Text
                    textStyle="Website/paragraph-small-bold"
                    textTransform="uppercase"
                    color="custom.primary.700"
                    textAlign="start"
                  >
                    {step.text}
                  </Text>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel py={1} px={1}>
                <Flex
                  gap={4}
                  height="full"
                  alignItems="stretch"
                  justifyItems="start"
                >
                  <Flex
                    alignItems="stretch"
                    justifyItems="center"
                    justifyContent="center"
                    maxWidth={8}
                    w={8}
                    flexShrink={0}
                  >
                    {i != steps.length - 1 && (
                      <Box
                        borderRadius="xl"
                        border={4}
                        transition="height 0.3s ease-in-out"
                        borderStyle="solid"
                        borderColor="custom.neutral.200"
                      />
                    )}
                  </Flex>
                  <Text
                    ml={3}
                    pt={1}
                    pb={2}
                    textStyle="Website/paragraph-small"
                  >
                    {step.subtext}
                  </Text>
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );

  const displayButton = button && (
    <Box mt={6}>
      <LinkButton
        href={button.url}
        colorScheme="amica-grey"
        variant="solid"
        size={button.size ?? 'small'}
        data-gtm-id="link-section-steps-accordion"
        data-gtm-tag={button.dataTagId}
        leftIcon={<ArrowForwardIcon />}
      >
        {button.text}
      </LinkButton>
    </Box>
  );

  const displayLeft = (
    <Flex
      direction="column"
      w={{ base: '100%', lg: '50%' }}
      key="content"
      maxW="content.lg"
    >
      {displaySubheader}
      {displayHeader}
      <Flex
        direction="column"
        minH={{ base: '3xs', lg: '2xs' }}
        justify="space-between"
      >
        {displaySteps}
        {displayButton}
      </Flex>
    </Flex>
  );

  const displayRight = (
    <Box
      w={{ base: '100%', lg: '50%' }}
      h="auto"
      key="media"
      className="media"
      textAlign={{ base: 'center', lg: 'end' }}
    >
      {steps[step].image && (
        <Image
          key="image"
          src={steps[step].image!.url}
          width={steps[step].image!.width}
          height={steps[step].image!.height}
          quality={100}
          zIndex={2}
          alt=""
          maxW="100%"
        />
      )}
    </Box>
  );

  return (
    <SectionLeftRight
      layout="left"
      alignItems={{ base: 'center', lg: 'start' }}
      spacing={{ base: 8, lg: 8 }}
    >
      {[displayLeft, displayRight]}
    </SectionLeftRight>
  );
};

type StepIndexProps = {
  current: boolean;
  index: number;
};

const StepIndex: React.FC<StepIndexProps> = ({ current, index }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    color={current ? 'custom.neutral.25' : 'custom.primary.700'}
    backgroundColor={current ? 'custom.primary.700' : 'custom.primary.50'}
    transition="background-color 0.3s ease-in-out, color 0.3"
    borderRadius="full"
    minWidth={8}
    minHeight={8}
    w={8}
    h={8}
  >
    <Text textStyle="Website/paragraph-bold">{index}</Text>
  </Flex>
);

export default SectionStepsAccordion;
