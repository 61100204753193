import React from 'react';

import { Flex, Text, Box } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

import { Link } from '@/theme/chakra-elements';
import { ButtonArrowRound } from '@/components/ui';

export type CardResourcesProps = {
  linkName: string;
  linkDescription: string;
  link: string;
} & FlexProps;

const CardResources: React.FC<CardResourcesProps> = ({
  linkName: title,
  linkDescription: description,
  link: url,
  ...rest
}) => {
  return (
    <Box maxWidth="612px" data-gtm-id="card-resources">
      <Link
        href={url}
        _hover={{ textDecoration: 'none' }}
        data-gtm-id="link-card-resources"
      >
        <Box role="group">
          <Flex
            p={4}
            justify="space-between"
            boxShadow="md"
            backgroundColor="white"
            alignItems="center"
            borderRadius="md"
            _hover={{ boxShadow: 'lg' }}
            {...rest}
          >
            <Flex flexDirection="column">
              <Text
                textStyle="button"
                fontSize="18px"
                _groupHover={{
                  textDecoration: 'underline',
                  color: 'custom.primary.700',
                }}
                mb={description ? 3 : 0}
              >
                {title}
              </Text>

              <Text textStyle="body 2">{description}</Text>
            </Flex>
            <ButtonArrowRound deactivated={true} url={url} />
          </Flex>
        </Box>
      </Link>
    </Box>
  );
};

export default CardResources;
