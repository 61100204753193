import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

import mattersSvg from '@/images/statistic-matters.svg';
import assetsSvg from '@/images/statistic-assets.svg';
import feesSvg from '@/images/statistic-fees.svg';
import parentingSvg from '@/images/statistic-parenting.svg';

type IconOptions = 'matters' | 'assets' | 'fees' | 'parenting';

export type CardQuickLinkStatisticProps = {
  heading: string;
  content: string;
  icon?: IconOptions;
} & FlexProps;

const CardQuickLinkStatistic: React.FC<CardQuickLinkStatisticProps> = ({
  icon = 'matters',
  heading,
  content,
  ...rest
}) => {
  const setIcon =
    {
      matters: mattersSvg.src,
      assets: assetsSvg.src,
      fees: feesSvg.src,
      parenting: parentingSvg.src,
    }[icon] || mattersSvg.src;

  return (
    <Box role="group" gap="0">
      <Flex
        flexDirection="column"
        justify="start"
        align="center"
        borderRadius="xl"
        backgroundColor="#FFFFFF"
        height="100%"
        width="100%"
        py={{ base: 4, sm: 6, md: 8 }}
        px={{ base: 2, sm: 4, md: 6 }}
        position="relative"
        gap="0"
        {...rest}
      >
        <Box height="32px" mb={{ base: 1, sm: 4 }}>
          <Image
            alt="Statistic Icon"
            src={setIcon}
            ignoreFallback
            height="100%"
            quality={100}
          />
        </Box>

        <Text
          as="h3"
          textStyle="Website/h3"
          textAlign="center"
          wordBreak="break-word"
          color="custom.primary.700"
          pt={1}
        >
          {content}
        </Text>
        <Text textStyle="Website/paragraph-small" textAlign="center">
          {heading}
        </Text>
      </Flex>
    </Box>
  );
};

export default CardQuickLinkStatistic;
