import React from 'react';

import { Flex, Button, Box } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

import Markdown from '@/components/ui/Markdown/Markdown';
import type { ButtonProps } from '@/components/ui';

export type TextBlockColumnsProps = {
  columns: { firstColumn: string; secondColumn: string };
  button?: ButtonProps;
} & FlexProps;

const TextBlockColumns: React.FC<TextBlockColumnsProps> = ({
  columns,
  button,
  ...rest
}) => {
  return (
    <Flex flexDirection="column" data-gtm-id="text-block-columns" {...rest}>
      <Flex sx={{ a: { color: 'custom.primary.700' } }} wrap="wrap">
        <Box width="400px">
          <Markdown body={columns.firstColumn} />
        </Box>
        <Box width="400px">
          <Markdown body={columns.secondColumn} />
        </Box>
      </Flex>

      {button && (
        <Button
          href={button.url}
          width="fit-content"
          colorScheme={button.colorScheme}
          data-gtm-id="link-text-block-columns"
          data-gtm-tag={button.dataTagId}
        >
          {button.text}
        </Button>
      )}
    </Flex>
  );
};

export default TextBlockColumns;
