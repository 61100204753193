import React from 'react';
import {
  Flex,
  Box,
  Text,
  Icon,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { LinkButton } from '@/theme/chakra-elements';

import theme from '@/theme/_generated/index';
import type { FlexProps } from '@chakra-ui/react';
import { FormalIcon, InformalIcon } from '@/theme/icons';

export type CardPricingProps = {
  formal: boolean;
  price: string;
  title: string;
  description: string;
  list: string[];
  url: string;
} & FlexProps;

const CardPricing: React.FC<CardPricingProps> = ({
  formal,
  price,
  title,
  description,
  list,
  url,
  ...rest
}) => {
  return (
    <Flex
      borderWidth="2px"
      borderColor={formal ? 'custom.supporting.400' : 'custom.primary.600'}
      backgroundColor="white"
      height="550px"
      maxWidth="400px"
      p={theme.space[6]}
      flexDirection="column"
      borderRadius={theme.radii.md}
      data-gtm-id="card-pricing"
      {...rest}
    >
      <Flex height="100%" flexDirection="column" justify="space-between">
        <Box>
          <Flex
            height="30px"
            mb={theme.space[8]}
            alignItems="center"
            justify="space-between"
          >
            <Flex alignItems="center">
              {formal ? (
                <Icon
                  overflow="initial"
                  mb="5px"
                  w="20px"
                  h="31px"
                  as={FormalIcon}
                />
              ) : (
                <Icon w="20px" h="26px" as={InformalIcon} />
              )}
              <Text textStyle="label" ml={theme.space[4]}>
                {formal ? 'Court Application' : 'Agreement'}
              </Text>
            </Flex>
            <Text textStyle="label">${price} +GST</Text>
          </Flex>
          <Text textStyle="h4" mb={theme.space[4]}>
            {title}
          </Text>
          <Text textStyle="body 1" mb={theme.space[8]}>
            {description}
          </Text>
          {list && list.length && (
            <UnorderedList>
              {list.map((item, i) => (
                <ListItem mb={theme.space[2]} key={i}>
                  <Text textStyle="body 2">{item}</Text>
                </ListItem>
              ))}
            </UnorderedList>
          )}
        </Box>
        <Box>
          <LinkButton
            variant="solid"
            colorScheme={formal ? 'amica-green' : 'amica-purple'}
            w="100%"
            href={url}
            whiteSpace="normal"
            height="auto"
            paddingY={2}
            data-gtm-id="link-card-pricing"
            data-gtm-tag="learn-more"
          >
            Learn more about {title}
          </LinkButton>
        </Box>
      </Flex>
    </Flex>
  );
};

export default CardPricing;
