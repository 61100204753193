import { SectionBlock } from '@/components/layout';
import {
  SectionQuickLinksDefault,
  SectionQuickLinksStatistics,
  SectionQuickLinksPanels,
} from '@/components/section';
import { CardQuickLinkNoIconProps } from '@/components/ui/CardQuickLinkNoIcon';
import { CardQuickLinkStatisticProps } from '@/components/ui/CardQuickLinkStatistic/CardQuickLinkStatistic';
import { CardQuickLinkPanelProps } from '@/components/ui/CardQuickLinkPanel/CardQuickLinkPanel';
import React from 'react';
import { SectionQuickLinksDefaultProps } from './SectionQuickLinks-Default';
import { SectionQuickLinksStatisticProps } from './SectionQuickLinks-Statistics';
import { SectionQuickLinksPanelProps } from './SectionQuickLinks-Panels';

export type SectionQuickLinkTypes = 'link' | 'statistic' | 'panel';

export type SectionQuickLinksNoIconProps = {
  __typename: 'QuicklinksGrid';
  type?: SectionQuickLinkTypes;
  heading?: string;
  subtext?: string;
  quicklinksCollection: {
    items:
      | CardQuickLinkStatisticProps[]
      | CardQuickLinkNoIconProps[]
      | CardQuickLinkPanelProps[];
  };
};

const SectionQuickLinksNoIcon: React.FC<SectionQuickLinksNoIconProps> = (
  props
) => {
  const displayGrid = {
    link: (
      <SectionQuickLinksDefault {...(props as SectionQuickLinksDefaultProps)} />
    ),
    statistic: (
      <SectionQuickLinksStatistics
        {...(props as SectionQuickLinksStatisticProps)}
      />
    ),
    panel: (
      <SectionQuickLinksPanels {...(props as SectionQuickLinksPanelProps)} />
    ),
  };

  return (
    <SectionBlock data-gtm-id="section-quick-links-no-icon">
      {displayGrid[(props.type as SectionQuickLinkTypes) || 'link']}
    </SectionBlock>
  );
};

export default SectionQuickLinksNoIcon;
