import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export type TestimonialProps = { description: string; author: string };

const CardTestimonial: React.FC<TestimonialProps> = ({
  description,
  author,
  ...rest
}) => {
  return (
    <Flex
      py={{ base: 6, md: 10 }}
      px={{ base: 4, md: 8 }}
      mb={{ base: 4, md: 0 }}
      mx={3}
      gap="30px"
      spacing="30px"
      backgroundColor="white"
      borderRadius="xl"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
      flexBasis={0}
      {...rest}
    >
      <Text textStyle="Website/h5" align="center" mb={{ base: 3, md: 10 }}>
        {description}
      </Text>
      <Text textStyle="label">{author}</Text>
    </Flex>
  );
};

export default CardTestimonial;
