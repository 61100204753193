import React from 'react';
import { Button } from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

type Props = ButtonProps;

const ButtonNext: React.FC<Props> = ({ children = 'Forward', ...props }) => {
  return (
    <Button
      colorScheme="amica-purple"
      rightIcon={<ArrowForwardIcon />}
      data-gtm-id="button-next"
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonNext;
