import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { Image, Link } from '@/theme/chakra-elements';

export type BlogTileProps = {
  title: string;
  slug: string;
  author?: string;
  featureImage?: {
    url: string;
    height: string;
    width: string;
    description?: string;
  };
};

type BlogTileTemplateProps = BlogTileProps & FlexProps;

const BlogTile: React.FC<BlogTileTemplateProps> = ({
  title,
  author,
  slug,
  featureImage,
  ...rest
}) => {
  return (
    <Box width={{ base: '100%', md: '328px', lg: '340px' }} {...rest}>
      <Link
        href={slug}
        _hover={{ textColor: '#393189', textDecoration: 'underline' }}
      >
        <Box width="100%" overflow="hidden">
          {featureImage && (
            <Image
              src={featureImage.url}
              alt={featureImage.description || ''}
              quality={100}
              borderRadius="16px"
              width={featureImage.width}
              height={featureImage.height}
              objectFit="cover"
              objectPosition="50% 50%"
              _hover={{
                filter: 'brightness(80%)',
                WebkitFilter: 'brightness(80%)',
                WebkitTransition: 'all 500ms ease',
                MozTransition: 'all 500ms ease',
                OTransition: 'all 500ms ease',
                msTransition: 'all 500ms ease',
                transition: 'all 500ms ease',
              }}
            />
          )}
        </Box>
        <Text
          as="h4"
          textStyle="h4"
          marginTop={{ base: 6, md: 8 }}
          marginBottom={4}
        >
          {title}
        </Text>
      </Link>
      <Text>By {author}</Text>
    </Box>
  );
};

export default BlogTile;
