import React from 'react';
import { Stack, Text, Box } from '@chakra-ui/react';

import { CardPricing } from '@/components/ui';
import type { CardPricingProps } from '@/components/ui/CardPricing';
import { SectionBlock } from '@/components/layout';

export type SectionPricingInformationGridProps = {
  __typename: 'PricingInformationGrid';
  heading?: string;
  cardsCollection: { items?: CardPricingProps[] };
};

const SectionPricingInformationGrid: React.FC<SectionPricingInformationGridProps> =
  ({ heading, cardsCollection = {} }) => {
    const { items: cards = [] } = cardsCollection;
    return (
      <SectionBlock data-gtm-id="section-pricing-information-grid">
        {heading && (
          <Text as="h3" textStyle="h3" marginBottom={12}>
            {heading}
          </Text>
        )}
        <Stack
          spacing={4}
          display={{ base: 'block', xl: 'flex' }}
          direction={{ base: 'column', xl: 'row' }}
        >
          {cards.map((card) => (
            <Box key={card.title}>
              <CardPricing {...card} />
            </Box>
          ))}
        </Stack>
      </SectionBlock>
    );
  };

export default SectionPricingInformationGrid;
