import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { LinkButton } from '@/theme/chakra-elements';
import { BannerProps } from '@/components/ui';
import { LogoAndTag } from '@/components/ui';
import { Markdown } from '@/components/ui';
import type { BannerBranding } from '@/components/ui/types';

type Props = FlexProps & BannerProps;

const getBrandingProps = (
  brand: BannerBranding
): {
  Box: {
    [key: string]: string;
  };
  LinkButton: {
    [key: string]: string;
  };
  Logo: {
    branding: BannerBranding;
  };
  Heading: {
    as: 'h2' | 'h3';
  };
} => {
  switch (brand) {
    case 'Amica One':
      return {
        Box: {
          backgroundColor: 'white',
        },
        LinkButton: {
          colorScheme: 'amica-purple',
        },
        Logo: {
          branding: 'Amica One',
        },
        Heading: {
          as: 'h3',
        },
      };
      break;
    default:
      return {
        Box: {
          backgroundColor: 'custom.accent.200',
        },
        LinkButton: {
          colorScheme: 'amica-orange',
        },
        Logo: {
          branding: 'None',
        },
        Heading: {
          as: 'h2',
        },
      };
      break;
  }
};

const BannerSecondary: React.FC<Props> = ({
  heading,
  text,
  branding,
  button,
  ...rest
}) => {
  const brandingProps = getBrandingProps(branding);

  return (
    <Box
      backgroundColor={brandingProps.Box.backgroundColor}
      color="custom.neutral.900"
      padding={{ base: 6, md: 8, xl: 8 }}
      shadow={{ xl: 'lg' }}
      borderRadius="lg"
      width="100%"
      maxWidth={{ base: 'auto', sm: '403px' }}
      data-gtm-id="banner-secondary"
      {...rest}
    >
      <Box marginBottom={{ base: 8, md: 12 }}>
        <LogoAndTag logoHeight={6} branding={brandingProps.Logo.branding} />
        <Markdown
          body={`${brandingProps.Heading.as === 'h2' ? '##' : '###'}${heading}`}
          overrides={{
            h2: {
              component: Text,
              props: {
                textStyle: 'h2 conversational',
                marginBottom: 6,
              },
            },
            h3: {
              component: Text,
              props: {
                textStyle: 'h2 conversational',
                marginBottom: 6,
              },
            },
          }}
        />
        <Markdown body={text} />
      </Box>
      {button && (
        <LinkButton
          href={button.url}
          colorScheme={brandingProps.LinkButton.colorScheme}
          variant="solid"
          size="big"
          fontSize={{ base: 'xs', sm: 'sm' }}
          width={{ base: 'auto', md: '100%' }}
          data-gtm-id="button-banner-secondary"
          data-gtm-tag={button.dataTagId}
        >
          {button.text}
        </LinkButton>
      )}
    </Box>
  );
};

export default BannerSecondary;
