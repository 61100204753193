import React from 'react';
import { Stack, Text, Box } from '@chakra-ui/react';
import { CardResources } from '@/components/ui';
import { CardResourcesProps } from '@/components/ui/CardResources';
import { SectionBlock } from '@/components/layout';

export type SectionResourcesProps = {
  __typename?: 'Resources';
  heading?: string;
  linksCollection: {
    items: CardResourcesProps[];
  };
};

const SectionResources: React.FC<SectionResourcesProps> = ({
  heading,
  linksCollection: { items: links },
}) => {
  return (
    <SectionBlock data-gtm-id="section-resources">
      {heading && (
        <Text as="h3" textStyle="h3" marginBottom={12}>
          {heading}
        </Text>
      )}
      <Stack spacing={{ base: 4, md: 8 }}>
        {links.map(({ linkName, linkDescription, link }) => (
          <Box key={`${linkName}${link}`}>
            <CardResources
              linkName={linkName}
              linkDescription={linkDescription}
              link={link}
            />
          </Box>
        ))}
      </Stack>
    </SectionBlock>
  );
};

export default SectionResources;
