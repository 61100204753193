import React from 'react';

import { Text } from '@chakra-ui/react';

import { SectionBlock } from '@/components/layout';
import { SliderTestimonial } from '@/components/ui';
import { TestimonialProps } from '@/components/ui/CardTestimonial';

export type SectionSliderTestimonialProps = {
  __typename: 'SliderTestimonial';
  heading: string;
  testimonialsCollection: {
    items: TestimonialProps[];
  };
  hideSlider: boolean;
};

const SectionSliderTestimonial: React.FC<SectionSliderTestimonialProps> = ({
  heading,
  testimonialsCollection,
  hideSlider = false,
  ...rest
}) => {
  const { items: testimonials } = testimonialsCollection;
  return (
    <SectionBlock data-gtm-id="section-slider-testimonial" {...rest}>
      {heading && (
        <Text textStyle="Website/h2" align="center" mb={14}>
          {heading}
        </Text>
      )}
      <SliderTestimonial testimonials={testimonials} hideSlider={hideSlider} />
    </SectionBlock>
  );
};

export default SectionSliderTestimonial;
