import React from 'react';

import { SectionBlock } from '@/components/layout';
import { BannerPrimary } from '@/components/ui';
import type { BannerProps } from '@/components/ui';

export type SectionBannerPrimaryProps = {
  __typename: 'BannerPrimary';
  banner: BannerProps;
};

const SectionBannerPrimary: React.FC<SectionBannerPrimaryProps> = ({
  banner,
  ...rest
}) => {
  return (
    <SectionBlock {...rest} data-gtm-id="section-banner-primary">
      <BannerPrimary {...banner} />
    </SectionBlock>
  );
};

export default SectionBannerPrimary;
