import React from 'react';
import TextBlock from '@/components/ui/TextBlock';
import TextBlockColumns from '@/components/ui/TextBlockColumns';
import { SectionBlock } from '@/components/layout';

export type SectionColumnsProps = {
  __typename: 'ContactColumns';
  copyTop: string;
  copyColumns: { firstColumn: string; secondColumn: string };
  copyBottom: string;
};

const SectionColumns: React.FC<SectionColumnsProps> = ({
  copyTop,
  copyColumns,
  copyBottom,
}) => {
  return (
    <SectionBlock data-gtm-id="section-columns">
      <TextBlock color="black" content={copyTop} />
      <TextBlockColumns columns={copyColumns} />
      <TextBlock color="black" content={copyBottom} />
    </SectionBlock>
  );
};

export default SectionColumns;
