import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { LinkButton } from '@/theme/chakra-elements';
import { BlogTile } from '@/components/ui';
import type { BlogTileProps } from '@/components/ui/BlogTile';

export type SectionRecentArticlesProps = {
  __typename: 'RecentArticles';
  heading: string;
  buttonText: string;
  buttonUrl: string;
  articlesCollection?: {
    items: BlogTileProps[];
  };
};

const SectionRecentArticles: React.FC<SectionRecentArticlesProps> = ({
  heading,
  buttonText,
  buttonUrl,
  articlesCollection,
}) => {
  const recentArticles = !!articlesCollection ? articlesCollection.items : [];
  return (
    <Box
      data-gtm-id="section-recent-articles"
      paddingY={{ base: 12, xl: 24 }}
      backgroundColor="custom.neutral.25"
      marginLeft={{ base: -4, md: -6, lg: -16, xl: 0 }}
      marginRight={{ base: -4, md: -6, lg: -16, xl: 0 }}
      paddingX={{ base: 4, md: 6, lg: 16, xl: 16 }}
      borderRadius={{ base: '16px', lg: '24px', xl: '32px' }}
    >
      {heading && (
        <Text as="h3" textStyle="h3" marginBottom={12}>
          {heading}
        </Text>
      )}
      <Flex
        spacing={6}
        direction={{ base: 'column', md: 'row' }}
        flexWrap="wrap"
        marginLeft={{ base: 0, md: -10, lg: -16, xl: -12 }}
        justifyContent={{ base: 'flex-start', xl: 'space-between' }}
      >
        {recentArticles.map((article, i) => {
          if (i === recentArticles.length - 1) {
            return (
              <BlogTile
                key={`${article.title}-${i}`}
                {...article}
                marginLeft={{ base: '0', md: 10, lg: 16, xl: 12 }}
                marginBottom={{ base: 12, md: 16, xl: 24 }}
                display={{ base: 'none', xl: 'block' }}
              />
            );
          }
          return (
            <BlogTile
              key={`${article.title}-${i}`}
              {...article}
              marginLeft={{ base: '0', md: 10, lg: 16, xl: 12 }}
              marginBottom={{ base: 12, md: 16, xl: 24 }}
            />
          );
        })}
      </Flex>
      <Box>
        <LinkButton
          href={buttonUrl}
          rightIcon={<ArrowForwardIcon height={4} width={4} />}
        >
          {buttonText}
        </LinkButton>
      </Box>
    </Box>
  );
};

export default SectionRecentArticles;
