import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import ButtonArrowRound from '../ButtonArrowRound/ButtonArrowRound';

import houseSvg from '@/images/about-house.svg';
import parentSvg from '@/images/about-parent.svg';
import priceSvg from '@/images/about-price.svg';
import { Link } from '@/theme/chakra-elements';

type IconOptions = 'house' | 'parent' | 'price';

export type CardQuickLinkProps = {
  icon: IconOptions;
  heading: string;
  content: string;
  url: string | null;
} & FlexProps;

const CardQuickLink: React.FC<CardQuickLinkProps> = ({
  icon = 'house',
  heading,
  content,
  url,
  ...rest
}) => {
  const setIcon =
    {
      house: houseSvg.src,
      parent: parentSvg.src,
      price: priceSvg.src,
    }[icon] || houseSvg;

  return (
    <Link
      href={url === null ? '/' : url}
      _hover={{ textDecoration: 'none' }}
      data-gtm-id="card-quick-link"
    >
      <Box role="group" height="100%" width="100%">
        <Flex
          flexDirection="column"
          borderRadius="md"
          backgroundColor="#FFFFFF"
          height="100%"
          width="100%"
          p={6}
          position="relative"
          borderWidth="1px"
          borderColor="white"
          _groupHover={{
            boxShadow: 'lg',
            borderColor: 'custom.neutral.100',
          }}
          {...rest}
        >
          <Box height="36px" mb={6}>
            <Image
              alt="Quicklink Icon"
              src={setIcon}
              ignoreFallback
              height="100%"
              quality={100}
            />
          </Box>
          <Text
            textStyle="h4"
            mb={6}
            _groupHover={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {heading}
          </Text>
          <Text mb={16} textStyle="body 1">
            {content}
          </Text>
          <Box position="absolute" bottom={6} right={6}>
            <ButtonArrowRound
              deactivated={true}
              url={url === null ? '/' : url}
            />
          </Box>
        </Flex>
      </Box>
    </Link>
  );
};

export default CardQuickLink;
