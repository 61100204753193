import React from 'react';

import { Box } from '@chakra-ui/react';
import { ArrowForwardIcon, ExternalLinkIcon } from '@chakra-ui/icons';

import type { FlexProps } from '@chakra-ui/react';

import { isExternalLink } from '@/utils/helpers';
import { Link } from '@/theme/chakra-elements';

export type ButtonArrowRoundProps = {
  deactivated?: boolean;
  isExternal?: boolean;
  url: string;
} & FlexProps;

const ButtonArrowRound: React.FC<ButtonArrowRoundProps> = ({
  deactivated = false,
  url,
  isExternal = isExternalLink(url),
}) => {
  const button = (isExternal: boolean) => (
    <Box
      h="40px"
      w="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      backgroundColor="custom.primary.50"
      flexShrink={0}
      _groupHover={{ backgroundColor: 'custom.primary.200' }}
      _hover={{
        bg: 'custom.primary.200',
        cursor: 'pointer',
      }}
    >
      {isExternal ? <ExternalLinkIcon /> : <ArrowForwardIcon />}
    </Box>
  );

  return deactivated ? (
    button(isExternal)
  ) : (
    <Link href={url}>{button(isExternal)}</Link>
  );
};

export default ButtonArrowRound;
