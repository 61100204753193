import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Image, Link } from '@/theme/chakra-elements';
import type { FlexProps } from '@chakra-ui/react';
import type { ImageLinkProps, ImageProps } from '@/components/ui';

export type BannerFeaturedOnProps = {
  imageLinks: ImageLinkProps[];
  heading?: string;
} & FlexProps;

const BannerFeaturedOn: React.FC<BannerFeaturedOnProps> = ({
  imageLinks = [],
  heading,
  ...rest
}) => {
  const displayImage = (image: ImageLinkProps['image']) => (
    <Image
      width={image.width}
      height={image.height}
      src={image.url}
      alt=""
      quality={100}
      alignSelf="center"
      justifySelf="center"
    />
  );
  return (
    <Box
      backgroundColor="custom.neutral.25"
      width="100%"
      data-gtm-id="banner-featured-on"
      pt={{ base: 4 }}
      px={{ base: 4, md: 0 }}
      {...rest}
    >
      {heading && (
        <Flex alignItems="center" justify="center" mb={{ base: 3, lg: 4 }}>
          <Text textStyle="Website/paragraph-small-bold">{heading}</Text>
        </Flex>
      )}
      <Flex justifyContent="center" justifyItems="center" flexWrap="wrap">
        {imageLinks &&
          imageLinks.map(({ image, description, link }, index) => {
            return (
              <Flex
                key={index}
                justifyContent="center"
                alignContent="center"
                mx={{ base: 2, md: 1 }}
                my={{ base: 2, md: 0 }}
                width="100%"
                flex={{
                  base: '0 0 calc(28%)',
                  md: '0 0 calc(19%)',
                }}
              >
                {link ? (
                  <Link
                    href={link}
                    data-gtm-id="link-banner-featured-on"
                    {...(description ? { 'aria-label': description } : {})}
                  >
                    {displayImage(image)}
                  </Link>
                ) : (
                  displayImage(image)
                )}
              </Flex>
            );
          })}
      </Flex>
    </Box>
  );
};

export default BannerFeaturedOn;
