import React from 'react';

import AccordionComponent from '@/components/ui/Accordion';
import { SectionBlock } from '@/components/layout';

type AccordianItemProps = {
  heading: string;
  content: string;
};

export type SectionAccordionProps = {
  __typename: 'Accordion';
  heading: string;
  itemsCollection: {
    items: AccordianItemProps[];
  };
};

const SectionAccordion: React.FC<SectionAccordionProps> = ({
  heading,
  itemsCollection,
}) => {
  return (
    <SectionBlock data-gtm-id="section-accordion">
      <AccordionComponent
        title={heading}
        list={itemsCollection.items.map((i) => ({
          title: i.heading,
          description: i.content,
        }))}
      />
    </SectionBlock>
  );
};

export default SectionAccordion;
