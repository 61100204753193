import React from 'react';
import { Stack, StackProps, Image } from '@chakra-ui/react';
import BlackLogo from '@/images/dark.svg';
import OneBlackLogo from '@/images/amica-one-dark.svg';
import type { BannerBranding } from '@/components/ui/types';

const LogoAndTag: React.FC<
  {
    branding: BannerBranding;
    logoHeight?: number;
  } & StackProps
> = ({ branding, logoHeight = 6, ...rest }) => {
  let Logo;

  switch (branding) {
    case 'Amica':
      Logo = BlackLogo;
      break;
    case 'Amica One':
      Logo = OneBlackLogo;
      break;
    default:
      Logo = null;
  }

  if (Logo == null) {
    return null;
  }

  return (
    <Stack
      spacing={3}
      direction="row"
      h={8}
      mb={8}
      alignItems="center"
      {...rest}
    >
      <Image
        height={logoHeight}
        src={typeof Logo === 'string' ? Logo : Logo.src}
        alt=""
      />
    </Stack>
  );
};

export default LogoAndTag;
