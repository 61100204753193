import React from 'react';
import { Button } from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';

type Props = {
  isSelected: boolean;
} & ButtonProps;

const ButtonQuestion: React.FC<Props> = ({
  isSelected,
  onClick,
  children,
  ...rest
}) => {
  return (
    <Button
      display="block"
      borderRadius="18px"
      colorScheme={isSelected ? 'amica-purple' : 'amica-grey'}
      color={isSelected ? 'white' : 'custom.neutral.800'}
      fontWeight="400"
      height="auto"
      whiteSpace="normal"
      textAlign="left"
      py="7px"
      onClick={onClick}
      mb={6}
      sx={{
        // Need to disable this to track clicks on gtm
        span: {
          pointerEvents: 'none',
        },
      }}
      data-gtm-id="button-question"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonQuestion;
