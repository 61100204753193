import { SectionGrid } from '@/components/layout';
import { CardQuickLinkPanel } from '@/components/ui';
import { CardQuickLinkPanelProps } from '@/components/ui/CardQuickLinkPanel/CardQuickLinkPanel';
import { Text, Stack, Box } from '@chakra-ui/react';
import React from 'react';
import { SectionQuickLinksNoIconProps } from './SectionQuickLinksNoIcon';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useBreakpointValue } from '@chakra-ui/react';

export type SectionQuickLinksPanelProps = SectionQuickLinksNoIconProps & {
  quicklinksCollection: {
    items: CardQuickLinkPanelProps[];
  };
};
const SectionQuickLinksPanels: React.FC<SectionQuickLinksPanelProps> = ({
  heading,
  quicklinksCollection: { items },
}) => {
  const displayHeading = heading && (
    <Text
      as="h4"
      textStyle="Website/h2"
      marginBottom={{ base: 10, md: 16 }}
      textAlign="center"
    >
      {heading}
    </Text>
  );

  const displayGrid = (
    <SectionGrid
      gap={4}
      templateColumns={{
        base: 'repeat(auto-fit, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(4, 1fr)',
      }}
      gridAutoFlow={{ base: 'column', sm: 'row' }}
      overflowX={{ base: 'auto', sm: 'hidden' }}
    >
      {items.map(({ heading, content, image }, i) => (
        <CardQuickLinkPanel
          heading={heading}
          content={content}
          image={image}
          key={`${i}`}
          minW={{ base: 'xs', sm: 'auto' }}
        />
      ))}
    </SectionGrid>
  );
  const settings = {
    className: 'slider',
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    infinite: false,
    arrows: false,
  };

  const displaySlider = (
    <Box
      sx={{
        '.slick-track': { display: 'flex', alignItems: 'stretch' },
        '.slick-slide': { height: 'auto', flex: 1 },
        '.slick-slide > div:first-of-type': { height: '100%' },
      }}
    >
      <Slider data-gtm-id="slider-why-use-amica" {...settings}>
        {items.map(({ heading, content, image }, i) => (
          <Box key={i} height="100%">
            <CardQuickLinkPanel
              heading={heading}
              content={content}
              image={image}
              key={`${i}`}
              mx={2}
              minW={{ base: '2xs', sm: 'auto' }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );

  const displayCardsForScreenSize = useBreakpointValue({
    base: displaySlider,
    sm: displayGrid,
  });

  return (
    <>
      <Stack>
        {displayHeading}
        {displayCardsForScreenSize}
      </Stack>
    </>
  );
};

export default SectionQuickLinksPanels;
