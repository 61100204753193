import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { SectionBlock, SectionLeftRight } from '@/components/layout';
import { TextBlock, LogoAndTag } from '@/components/ui';

import type { FlexProps } from '@chakra-ui/react';
import type { ImageProps } from '@/components/ui';
import type { TextBlockProps } from '@/components/ui/TextBlock';

import { Image } from '@/theme/chakra-elements';

export type BannerAmicaOneProps = {
  image?: ImageProps;
  textblock: TextBlockProps;
  heading?: string;
} & FlexProps;

const BannerAmicaOne: React.FC<BannerAmicaOneProps> = ({
  image,
  textblock,
  heading,
}) => {
  return (
    <SectionBlock
      className="section-media-text"
      borderRadius="xl"
      bg="custom.accent.200"
      py={{ base: 8, lg: 18 }}
      px={{ base: 4, lg: 16 }}
    >
      <SectionLeftRight layout="left" spacing={8}>
        {[
          <Flex
            key="image"
            flexDirection="column"
            flexBasis={{ base: '100%', lg: '70%' }}
            alignItems={{ base: 'center', lg: 'flex-start' }}
            justifyContent="space-between"
          >
            <Box>
              <LogoAndTag logoHeight={6} mb={6} branding="Amica One" />
              {heading && (
                <Text as="h2" textStyle="Website/h2" mb={{ base: 10, lg: 14 }}>
                  {heading}
                </Text>
              )}
            </Box>
            {image && (
              <Image
                src={image.url}
                width={image.width}
                height={image.height}
                alt={image.description}
                zIndex={2}
                quality={100}
              />
            )}
          </Flex>,
          <Flex
            key="textblock"
            alignSelf={{ base: 'center', lg: 'end' }}
            mt={1}
          >
            <TextBlock maxWidth={{ lg: 'xs' }} {...textblock} />
          </Flex>,
        ]}
      </SectionLeftRight>
    </SectionBlock>
  );
};

export default BannerAmicaOne;
