import React from 'react';
import TextBlock from '@/components/ui/TextBlock';
import { SectionBlock } from '@/components/layout';

export type SectionCopyProps = {
  __typename: 'SectionCopy';
  content: string;
};

const SectionCopy: React.FC<SectionCopyProps> = ({ content }) => {
  return (
    <SectionBlock data-gtm-id="section-copy">
      <TextBlock color="black" content={content} />
    </SectionBlock>
  );
};

export default SectionCopy;
