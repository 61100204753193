import React from 'react';
import { Flex, Box, useBreakpointValue } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { CardTestimonial } from '@/components/ui';
import { TestimonialProps } from '@/components/ui/CardTestimonial';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type SliderTestimonialProps = {
  testimonials: TestimonialProps[];
  hideSlider?: boolean;
} & FlexProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NextArrow = (props: any) => {
  const { onClick, className, currentSlide, slideCount } = props;

  const step = currentSlide + 1;
  const numberOfSlides = useBreakpointValue({ base: 1, md: 2, lg: 3 }) || 1;
  const hasNoMoreSlides =
    numberOfSlides === 1
      ? step >= slideCount
      : step * numberOfSlides > slideCount;
  const arrowBackground = hasNoMoreSlides ? '#8B87BF' : 'custom.primary.700';

  return (
    <ArrowForwardIcon
      backgroundColor={arrowBackground}
      _hover={{
        backgroundColor: arrowBackground,
        color: 'white',
      }}
      height="40px"
      width="40px"
      p={2}
      color="white"
      borderRadius="full"
      cursor="pointer"
      onClick={onClick}
      className={className}
      style={{
        position: 'absolute',
        bottom: '-70px',
        right: '0px',
        left: 'auto',
        top: 'auto',
      }}
      data-gtm-id="slider-testimonial-next-arrow"
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrevArrow = (props: any) => {
  const { onClick, className, currentSlide } = props;

  const hasNoMoreSlides = currentSlide === 0;
  const arrowBackground = hasNoMoreSlides ? '#8B87BF' : 'custom.primary.700';

  return (
    <ArrowBackIcon
      backgroundColor={arrowBackground}
      _hover={{
        backgroundColor: arrowBackground,
        color: 'white',
      }}
      height="40px"
      width="40px"
      p={2}
      color="white"
      borderRadius="full"
      cursor="pointer"
      onClick={onClick}
      className={className}
      style={{
        position: 'absolute',
        bottom: '-70px',
        right: '70px',
        left: 'auto',
        top: 'auto',
      }}
      data-gtm-id="slider-testimonial-prev-arrow"
    />
  );
};

const SliderTestimonial: React.FC<{ testimonials: TestimonialProps[] }> = ({
  testimonials = [],
}) => {
  const settings = {
    centerMode: false,
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        '.slick-track': { display: 'flex', alignItems: 'stretch' },
        '.slick-slide': { height: 'auto', flex: 1 },
        '.slick-slide > div:first-of-type': { height: '100%' },
      }}
    >
      <Slider
        className="sliderTestimonial"
        data-gtm-id="slider-testimonial"
        {...settings}
      >
        {testimonials.map((testimonial, i) => (
          <Box key={i} height="100%">
            <CardTestimonial {...testimonial} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

const StaticTestimonial: React.FC<{ testimonials: TestimonialProps[] }> = ({
  testimonials = [],
}) => {
  const limit = 3;
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'stretch', md: 'start' }}
    >
      {testimonials.slice(0, limit).map((testimonial, i) => (
        <CardTestimonial key={i} {...testimonial} />
      ))}
    </Flex>
  );
};

const SliderTestimonialWrapper: React.FC<SliderTestimonialProps> = ({
  testimonials = [],
  hideSlider = false,
}) => {
  return hideSlider ? (
    <StaticTestimonial testimonials={testimonials} />
  ) : (
    <SliderTestimonial testimonials={testimonials} />
  );
};

export default SliderTestimonialWrapper;
